.c-button {
  @extend %button-text;
  padding: 6px 36px;
  transition: color 0.3s $ease-out-expo, background 0.3s $ease-out-expo,
    border 0.3s $ease-out-expo;
  border-radius: 100px;

  &--primary {
    background: $green-6;
    color: $white;
    border: 0;

    @include cursor {
      &:hover {
        background: $green-5;
      }
    }
  }

  &--secondary {
    background: transparent;
    border: 1px solid $grey-6;
    color: $grey-6;

    @include cursor {
      &:hover {
        border: 1px solid $green-5;
        color: $green-5;
      }
    }
  }
}
