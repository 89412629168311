.c-values {
  @include media(">tablet") {
    display: flex;
    flex-wrap: nowrap;
    height: 100vh;
  }

  .c-value {
    padding: 60px 24px;
    position: relative;
    overflow: hidden;
    flex: 0 0 auto;

    @include media(">tablet") {
      width: 1325px;
      height: 100%;
      flex-shrink: 0;

      &:not(:last-child) {
        margin-right: 16px;
      }
    }

    &__icon {
      margin-bottom: 40px;
      display: flex;
      justify-content: flex-end;
    }

    &__soon {
      @extend %body-16;
      padding: 4px 50px;
      background: $charcoal-9;
      margin-bottom: 32px;
      display: inline-block;
      border-radius: 50px;

      @include media("<=tablet") {
        margin-bottom: 24px;
      }
    }

    &__pill {
      padding: 4px 36px;
      box-shadow: 4px 4px 0px $green-4;
      background: $white;
      border: 1px solid $charcoal-10;
      font-style: italic;
      position: relative;
      z-index: z("2");
      border-radius: 100px;
      display: inline-block;
      position: absolute;
      width: fit-content;

      @include media(">tablet") {
        padding: 4px 24px;
      }

      span {
        font-style: normal;
        @extend %title-24;
      }
    }

    &__profile {
      margin: 0 auto;
      margin-bottom: 96px;
      position: relative;
      z-index: z("1");
    }

    &__imagecon {
      max-width: 500px;
      margin: 0 auto;
      position: relative;
      @include ratio(315, 342);

      @include media("<=tablet") {
        background: $charcoal-9;
        border-radius: 100px;
        padding: 0 24px;
        padding-top: 40px;
      }
    }

    &__image {
      width: 100%;
      height: 100%;
      position: relative;
    }

    &__content {
      color: $white;

      @include media("<=tablet") {
        margin: 0 auto;
      }
    }

    &__title {
      @extend %title-52;
      margin-bottom: 24px;
    }

    &__description {
      @extend %title-20;

      @include media("<=tablet") {
        font-weight: 400;
      }
    }

    &--first {
      background: $charcoal-10;

      @include media(">tablet") {
        padding-right: 92px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      .c-value__icon {
        max-width: 156px;
        margin-left: auto;
        width: 100%;

        @include media("<=tablet") {
          max-width: 81px;
        }
      }

      .c-value__pill {
        right: 14px;
        bottom: -12px;
        transform: rotate(-12deg);

        @include media(">tablet") {
          right: -142px;
          bottom: 84px;
          transform: rotate(16.38deg);
        }
      }

      .c-value__content {
        max-width: 580px;
      }

      .c-value__profile {
        max-width: 506px;

        @include media(">tablet") {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          margin: 0;
        }
      }

      .c-value__line {
        display: none;

        @include media(">tablet") {
          display: block;
          position: absolute;
          left: -14px;
          bottom: 0;
          width: 100%;
          max-width: 522px;
        }
      }
    }

    &--second {
      background: $green-6;

      @include media(">tablet") {
        padding-right: 92px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      .c-value__icon {
        max-width: 96px;
        margin-left: auto;
        width: 100%;

        @include media("<=tablet") {
          max-width: 50px;
        }
      }

      .c-value__pill {
        left: 24px;
        bottom: -12px;
        transform: rotate(12deg);
        box-shadow: 4px 4px 0px #fff1b8;

        @include media(">tablet") {
          left: -142px;
          bottom: 72px;
          transform: rotate(-4deg);
        }
      }

      .c-value__profile {
        max-width: 506px;

        @include media(">tablet") {
          position: absolute;
          left: 50%;
          bottom: 0;
          width: 100%;
          margin: 0;
          transform: translateX(-50%);
        }
      }

      .c-value__line {
        display: none;

        @include media(">tablet") {
          display: block;
          position: absolute;
          left: 50%;
          bottom: -40px;
          width: 100%;
          margin: 0;
          transform: translateX(-60%);
          max-width: 581px;
        }
      }

      .c-value__content {
        @include media("<=tablet") {
          max-width: 580px;
          margin: 0 auto;
        }
      }

      .c-value__title {
        @include media(">tablet") {
          max-width: 395px;
          position: absolute;
          left: 80px;
          // top: 17.4vh;
          top: 151px;
        }
      }

      .c-value__description {
        @include media(">tablet") {
          max-width: 364px;
          position: absolute;
          right: 46px;
          bottom: 122px;
        }
      }

      .c-value__imagecon {
        @include media("<=tablet") {
          background: $green-7;
        }
      }
    }

    &--third {
      background: $charcoal-10;

      @include media(">tablet") {
        padding-left: 80px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .c-value__icon {
        max-width: 62px;
        width: 100%;
        margin-left: auto;

        @include media(">tablet") {
          max-width: 117px;
          margin-left: unset;
          margin-bottom: 105px;
        }
      }

      .c-value__pill {
        right: 14px;
        bottom: -12px;
        transform: rotate(-12deg);

        @include media(">tablet") {
          left: -180px;
          bottom: 60px;
          transform: rotate(7deg);
        }
      }

      .c-value__content {
        @include media("<=tablet") {
          max-width: 580px;
          margin: 0 auto;
        }
      }

      .c-value__title {
        @include media(">tablet") {
          max-width: 615px;
        }
      }

      .c-value__description {
        @include media(">tablet") {
          max-width: 587px;
        }
      }

      .c-value__profile {
        max-width: 462px;

        @include media(">tablet") {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 100%;
          margin: 0;
        }
      }

      .c-value__line {
        display: none;

        @include media(">tablet") {
          display: block;
          position: absolute;
          right: -18px;
          bottom: -7px;
          width: 100%;
          max-width: 522px;
        }
      }
    }
  }
}
