$black: #000000;
$white: #ffffff;
$green-0: #f2fff7;
$green-1: #e1fae9;
$green-2: #a8edc2;
$green-3: #7be0a4;
$green-4: #53d48a;
$green-5: #2ec775;
$green-6: #0dba63;
$green-7: #019348;
$grey-1: #f6f7f9;
$grey-3: #c1c8d7;
$grey-4: #b5bac6;
$grey-5: #919aad;
$grey-6: #80899b;
$grey-10: #101318;
$charcoal-4: #f0f0f0;
$charcoal-5: #d9d9d9;
$charcoal-6: #bfbfbf;
$charcoal-8: #595959;
$charcoal-9: #262728;
$charcoal-10: #0f1010;
$geek-blue-1: #f0f5ff;
$geek-blue-6: #2f54eb;
$geek-blue: #85a5ff;
$gold-2: #fff1b8;
$gold-4: #ffd666;
$gold-5: #ffc53d;
$orange-1: #fffbf6;
$orange-2: #fff3e3;
$red-2: #ffccc7;
$magenta-1: #fff0f6;

$error: #ff0000;

$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);

$z-indexes: ("menu", "modal", "nav", "5", "4", "3", "2", "1");
