.c-legal {
  margin-bottom: 180px;

  @include media("<=tablet") {
    margin-bottom: 100px;
  }

  &__hero {
    @extend %section-padding;
    padding-top: 100px;
    padding-bottom: 100px;
    color: $white;
    background: $green-6;
    text-align: center;
    margin-bottom: 112px;

    @include media("<=tablet") {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    &__title {
      @extend %title-64;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
  }

  b {
    font-weight: 600;
  }

  i {
    font-style: italic;
  }

  p {
    @extend %body-20;
  }

  h2 {
    @extend %title-36;
    margin-bottom: 40px;
  }

  h3 {
    @extend %title-24;
    margin-bottom: 12px;
  }

  &__section {
    @extend %section-padding;

    p {
      margin-bottom: revert;
    }

    a {
      color: $geek-blue-6;
      @extend %link;
    }

    &:not(:last-child) {
      margin-bottom: 72px;
    }

    .c-block {
      &--text {
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }

      &--list {
        list-style: disc;
        padding: revert;

        li {
          @extend %body-20;
          &:not(:last-child) {
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}
