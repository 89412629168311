.c-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: $white;
  z-index: z("menu");
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s $ease-out-expo;
  display: flex;
  flex-direction: column;

  @include media(">tablet") {
    display: none;
  }

  &.open {
    opacity: 1;
    pointer-events: auto;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    height: 69px;
    margin-bottom: 8px;
  }

  &__bottom {
    padding: 0 24px;
    margin-top: auto;
    margin-bottom: 24px;

    .c-button {
      width: 100%;
    }
  }

  &__close {
    background: none;
    border: 0;
  }

  &__item {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: calc(-0.005 * 16px);
    font-weight: 600;
    padding: 8px 24px;
    background: none;
    border: 0;
    display: block;
    color: inherit;
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
