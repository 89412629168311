.c-footer {
  @extend %section-padding;
  background: $grey-10;
  color: $white;

  &__top {
    padding-top: 32px;
    padding-bottom: 32px;

    @include media(">tablet") {
      display: flex;
      justify-content: space-between;
      padding-top: 60px;
      padding-bottom: 48px;
    }
  }

  &__logo {
    color: $green-6;
    @extend %title-40;
    margin-bottom: 32px;

    @include media(">tablet") {
      margin-right: 24px;
      margin-bottom: 0;
    }
  }

  &__links {
    @include media(">tablet") {
      display: flex;
    }

    &__left {
      @include media(">tablet") {
        display: flex;
        @include flex-gap(toRem(60));
      }

      .c-link {
        &__container {
          @include media("<=tablet") {
            margin-bottom: 28px;
          }
        }
      }
    }

    &__right {
      @include media(">tablet") {
        margin-left: toRem(120);
        max-width: 172px;
      }
    }

    .c-link {
      &__header {
        @extend %title-20;
        color: $charcoal-6;
        margin-bottom: 40px;

        @include media("<=tablet") {
          margin-bottom: 12px;
          color: $charcoal-8;
        }
      }

      &__item {
        display: flex;

        @include media("<=tablet") {
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
        }

        button,
        a {
          @extend %link--hidden;
          color: inherit;
          text-decoration: none;
          background-color: transparent;
          border: none;
          font-size: inherit;
          padding: 0;
        }

        &:not(:last-child) {
          margin-bottom: 18px;

          @include media("<=tablet") {
            margin-bottom: 12px;
          }
        }
      }
    }
  }

  &__address {
    margin: 40px 0;

    @include media("<=tablet") {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      margin: 0;
      padding-bottom: 32px;
      margin-bottom: 32px;
      border-bottom: 1px solid $charcoal-8;
    }
  }

  &__socials {
    @include flex-gap(24px);
    align-items: center;

    @include media(">tablet") {
      margin-top: 40px;
    }

    &__link {
      transition: color 0.3s $ease-out-expo;
      display: flex;
      @include cursor {
        &:hover {
          color: $green-6;
        }
      }
    }
  }

  &__bottom {
    border-top: 1px solid $charcoal-8;
    padding-top: 52px;
    padding-bottom: 60px;
    display: flex;
    justify-content: space-between;
    @include flex-gap(40px);
    flex-wrap: wrap;
    width: 100%;

    @include media("<=tablet") {
      font-size: 14px;
      line-height: 22px;
      padding: 32px 0;
      @include flex-gap(32px);
    }

    &__links {
      display: flex;
      flex-wrap: wrap;

      a {
        @extend %link--hidden;
      }

      > * {
        &:not(:last-child) {
          padding-right: 8px;
          margin-right: 8px;
          border-right: 1px solid $charcoal-5;
        }
      }
    }
  }

  &__copyright,
  &__rights {
    color: $charcoal-5;
  }
}
