.c-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 69px;
  position: sticky;
  top: 0;
  width: 100%;
  background: $white;
  @extend %section-padding;
  z-index: z("nav");
  background: $white;
  transition: transform 0.3s ease-out;

  &.scrolling {
    transform: translateY(-100%);
  }

  &__logo {
    color: $green-6;
    @extend %title-40;
  }

  &__links {
    @include flex-gap(32px);
    align-items: center;
    text-transform: capitalize;

    @include media("<=tablet") {
      display: none;
    }
  }

  &__link {
    font-size: 16px;
    @extend %link--hidden;
  }

  &__button {
    outline: none;
    border: 1px solid $green-7;
    background: transparent;
    border-radius: 100px;
    padding: 12.5px 32px;
    color: $green-7;
    @extend %body-14;
    transition: color 0.3s $ease-out-expo, background 0.3s $ease-out-expo;

    @include cursor {
      &:hover {
        background: $green-7;
        color: $white;
      }
    }
  }

  &__hamburger {
    background: none;
    border: 0;

    @include media(">tablet") {
      display: none;
    }
  }
}
