.c-info-section {
  @extend %section-padding;
  min-height: 100vh;
  width: 100%;
  display: flex;
  padding-top: 10.2vh;
  padding-bottom: 9.4vh;
  justify-content: space-between;
  background-color: $grey-10;
  position: relative;

  @media (max-width: 1000px) {
    flex-direction: column;
  }

  @include media("<=tablet") {
    flex-direction: column;
    justify-content: unset;
    padding-top: 0;
    align-items: center;
    padding-bottom: 0;
  }

  &--collaboration {
    @include media("<=tablet") {
      padding-bottom: 88px;
      padding-top: 68px;
    }
  }

  &--integrations {
    @include media("<=tablet") {
      padding-bottom: 40px;
      min-height: auto;
    }
  }

  &__squiggly {
    position: absolute;
    bottom: 25.9%;
    left: 0;
    z-index: 1;

    @include media("<=tablet") {
      display: none;
    }
  }

  &__details {
    margin-top: 210px;
    position: relative;
    z-index: 2;

    @media (max-width: 1000px) {
      margin: 0 auto 80px;
    }

    @include media("<=tablet") {
      margin-top: 0;
    }
  }

  &__title {
    @extend %title-52;
    margin-bottom: 20px;
    font-style: italic;
    color: $grey-10;
    text-shadow: -1px -1px 0 $white,
    1px -1px 0 $white,
    -1px 1px 0 $white,
    1px 1px 0 $white;
    position: relative;

    @media (max-width: 1000px) {
      width: fit-content;
      margin: 0 auto 12px;
    }

    @include media("<=tablet") {
      margin: 0 0 12px;
    }

    span {
      z-index: 2;
      position: relative;
    }

    &:after {
      position: absolute;
      transform: translateX(-5px) translateY(5px);
      left: 0;
      top: 0;
      z-index: 1;
    }

    &--collaboration {
      &:after {
        content: "Easily Foster";
        text-shadow: -1px -1px 0 $gold-5,
        1px -1px 0 $gold-5,
        -1px 1px 0 $gold-5,
        1px 1px 0 $gold-5;
      }
    }

    &--integrations {
      &:after {
        content: "Silky Smooth";
        text-shadow: -1px -1px 0 $green-6,
        1px -1px 0 $green-6,
        -1px 1px 0 $green-6,
        1px 1px 0 $green-6;
      }
    }
  }

  &__description {
    @extend %title-72;
    width: 100%;
    max-width: 491px;
    color: white;

    @media (max-width: 1000px) {
      text-align: center;
    }

    @include media("<=tablet") {
      text-align: left;
    }
  }

  &__card-wrapper {
    position: relative;
    width: 100%;
    max-width: 342px;
    margin: 0 auto;
  }

  &__card-star {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-59px) translateY(-57px);

    @include media("<=tablet") {
      width: 77px;
      height: 77px;
      transform: translate(-42px, -41px);
    }
  }

  &__card {
    padding: 0 14.5%;
    height: 100%;
    border-radius: 10px;
    width: 40vw;
    max-width: 567px;
    position: relative;

    &--collaboration {
      padding-top: 100px;
      background-color: $green-4;

      @media (max-width: 1000px) {
        height: 783px;
      }

      @include media("<=tablet") {
        height: 583px;
      }
    }

    &--integrations {
      padding-top: 20%;
      background-color: $gold-2;

      @media (max-width: 1000px) {
        padding-bottom: 70px;
      }
    }

    @include media("<=tablet") {
      width: 100%;
      max-width: 342px;
      padding: 50px 38px;
    }

  }

  &__recognition-card {
    box-shadow: 10px 10px 0px $gold-2;
  }

  &__card-hashtag {
    padding: 4px 24px;
    border: 1px solid $black;
    box-shadow: 4px 4px 0px $green-7;
    border-radius: 100px;
    position: absolute;
    width: fit-content;
    @extend %title-18;
    transform-origin: bottom right;

    &--world-class {
      transform: rotate(-17.7deg);
      background: $red-2;
      right: 13.2%;
      bottom: 13%;
      transform-origin: bottom left;

      @include media("<=tablet") {
        right: 7%;
        bottom: 15.78%;
      }
    }

    &--welcome {
      transform: rotate(21.42deg);
      background-color: $orange-2;
      left: 29.5%;
      bottom: 19.6%;

      @include media("<=tablet") {
        left: 24.85%;
        bottom: 25.55%;
      }
    }

    &--rockstar {
      transform: rotate(13.41deg);;
      background-color: white;
      left: 7.9%;
      bottom: 16.2%;

      @include media("<=tablet") {
        left: 5.5%;
        bottom: 17%;
      }
    }

    &--stronger {
      transform: rotate(4.62deg);;
      background-color: white;
      left: 26%;
      bottom: 4.26%;

      @include media("<=tablet") {
        bottom: 7.9%;
      }
    }
  }

  &__card-onion {
    @extend %title-64;
    background: $white;
    border: 1px solid $charcoal-4;
    box-shadow: 10px 10px 0px $green-6;
    border-radius: 100px;
    padding: 40px;
    color: $green-6;
    width: fit-content;
    margin: 0 auto 18%;

    @include media("<=tablet") {
      padding: 24px 40px;
      margin-bottom: 88px;
    }
  }

  &__card-integration-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;
  }

  &__card-integration-icon {
    background: $white;
    border: 1px solid $black;
    box-shadow: 10px 10px 0px $green-6;
    border-radius: 100px;
    padding: 20px 40px;
    width: fit-content;

    @include media("<=tablet") {
      padding: 20px 32px;
    }

    svg {
      width: 40px;
      height: 40px;
      @include media("<=tablet") {
        width: 24px;
        height: 24px;
      }
    }

    &--center {
      margin: 0 auto;
    }
  }
}