@import "utils/breakpoints";
@import "utils/functions";
@import "utils/mixins";

@import "core/reset";
@import "core/variables";
@import "core/fonts";
@import "core/globals";

@import "shared/fonts";
@import "shared/links";
@import "shared/sections";

@import "components/nav";
@import "components/button";
@import "components/form";
@import "components/footer";
@import "components/plans";
@import "components/recognition-card";
@import "components/workplace";
@import "components/value";
@import "components/modal";
@import "components/menu";

@import "pages/home";
@import "pages/collaboration";
@import "pages/hero";
@import "pages/legal";
