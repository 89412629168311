.c-hero {
  @extend %section-padding;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $green-0;
  position: relative;

  @include media("<=tablet") {
    justify-content: unset;
    padding-top: 56px;
  }

  &__beta {
    @extend %title-20;
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0 28px;
    background-color: $green-3;
    border-radius: 50px;
    margin-bottom: 16px;
    color: $charcoal-10;
    width: fit-content;

    span {
      display: inline-block;
      margin-right: 3px;
      animation: bellshake 0.6s 2.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      backface-visibility: hidden;
      transform-origin: top right;
    }
  }

  &__title {
    @extend %title-96;
    text-align: center;
    color: $grey-10;
    margin-bottom: 16px;
    width: 100%;
    max-width: 1121px;

    @include media("<=tablet") {
      max-width: 342px;
    }
  }

  &__subtext {
    @extend %title-24;
    text-align: center;
    font-weight: 400;
    color: $grey-10;
    margin-bottom: 72px;
    opacity: 0;

    @include media("<=tablet") {
      width: 100%;
      max-width: 342px;
      margin-bottom: 40px;
    }
  }

  &__cta {
    @extend %title-18;
    width: 354px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $grey-10;
    outline: none;
    color: $white;
    border: 1px solid $grey-10;
    border-radius: 100px;
    transition: color 0.3s cubic-bezier(0.19, 1, 0.22, 1),
      background 0.3s cubic-bezier(0.19, 1, 0.22, 1),
      border 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;

    &:hover {
      background-color: $green-0;
      color: $grey-10;
    }

    @include media("<=tablet") {
      width: 100%;
      max-width: 342px;
      height: 42px;
    }
  }

  &__recognition {
    display: grid;
    align-items: flex-start;
    column-gap: 4px;
    position: absolute;

    .c-hero__recognition__cursor {
      opacity: 0;
    }

    @include media("<=tablet") {
      display: none;
    }

    &--left {
      grid-template-columns: 1fr 1.43fr;
      left: 5.06%;
      bottom: 7.36%;
      width: 32.7rem;
      height: 23.3rem;

      [data-cursor-left-2] {
        position: relative;
        top: -5px;
        left: 15px;
      }
    }

    &--right {
      grid-template-columns: 1fr 2.6fr 1fr;
      top: 7.8%;
      right: 6.88%;
      width: 33.2rem;
      height: 23.1rem;
    }

    &__column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    &__cursor {
      width: 100%;

      &--small {
        width: 10.8rem;
      }
    }

    &__card {
      background: $white;
      border: 0.2rem solid $grey-10;
      box-shadow: 0.4rem 0.4rem 0 $green-6;
      border-radius: 1rem;
      padding: 1.6rem 1.2rem;
      position: relative;
      width: 100%;

      &--left {
        margin-top: 27.89%;
      }

      &--right {
        margin-top: 30%;
      }
    }

    &__card-header {
      display: flex;
      align-items: center;
      margin-bottom: 0.8rem;
    }

    &__card-onions-icon {
      margin-right: 1.6rem;
      width: 4rem;
    }

    &__card-icons-row {
      display: grid;
      grid-template-columns: repeat(3, 3.2rem);
      column-gap: 0.8rem;
    }

    &__card-icon {
      width: 100%;
    }

    &__title {
      font-size: 1.2rem;
      line-height: 1.67;
      font-weight: 600;
      color: $grey-10;
      margin-bottom: 0.8rem;

      span {
        span {
          opacity: 0;
        }
      }
    }

    &__divider {
      height: 0.4rem;
      width: 100%;
      background-color: $grey-1;
      border-radius: 0.2rem;
      display: block;
      border: none;
      margin: 0;
    }
  }

  &__star {
    position: absolute;
    bottom: 28.9%;
    right: 18.3%;
    width: 9rem;
    height: 10.3rem;
    max-width: 90px;
    max-height: 103px;

    @include media("<=desktop") {
      bottom: 18%;
    }

    @include media("<=tablet") {
      position: relative;
      margin: 0 30px 48px auto;
      width: 34px;
      height: 34px;
      bottom: unset;
      right: unset;
    }
  }

  &__recognition-mobile {
    width: 100%;
    margin-top: 40px;
    max-width: 342px;

    @include media(">tablet") {
      display: none;
    }
  }
}

@keyframes bellshake {
  0% {
    transform: rotate(0) scale(1);
  }
  15% {
    transform: rotate(5deg) scale(1);
  }
  30% {
    transform: rotate(-5deg) scale(1.06);
  }
  45% {
    transform: rotate(4deg) scale(1.08);
  }
  60% {
    transform: rotate(-4deg) scale(1.1);
  }
  75% {
    transform: rotate(2deg) scale(1.08);
  }
  85% {
    transform: rotate(-2deg) scale(1.06);
  }
  92% {
    transform: rotate(1deg) scale(1);
  }
  100% {
    transform: rotate(0) scale(1);
  }
}
