.c-form {
  .c-label {
    @extend %body-14;
    display: block;
    margin-bottom: 8px;
  }

  .c-input {
    width: 100%;
    color: $charcoal-9;
    font-size: 14px;
    font-family: inherit;
    text-indent: 12px;
    border-radius: 2px;
    border: 1px solid $grey-3;
    background: none;
    transition: border 0.3s $ease-out-expo;
    outline: none;
    @include autoFill($white, $charcoal-9);
    @include placeholder {
      color: $grey-3;
      font-size: inherit;
      font-family: inherit;
    }

    @include cursor {
      &:hover {
        border-color: $green-5;
      }
    }

    &:focus,
    &:active {
      border-color: $green-6;
      box-shadow: 0px 0px 4px rgba(24, 144, 255, 0.5);
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 36px;
    }
  }

  input {
    height: 40px;
  }

  textarea {
    height: 64px;
    resize: none;
    padding-top: 12px;
  }

  .c-file {
    &__input {
      position: absolute;
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      z-index: -1;
    }

    &__label {
      display: block;
      @extend %body-14;

      &__inner {
        border: 1px dashed $grey-3;
        height: 156px;
        width: 100%;
        padding: 24px 16px;
        display: block;
        text-align: center;
      }

      &__label {
        color: $charcoal-10;
        margin-bottom: 8px;
        display: inline-block;
      }

      &__icon {
        text-align: center;
        display: flex;
        justify-content: center;
        margin-bottom: 16px;
      }

      &__text {
        display: block;
        color: $grey-6;
      }
    }

    // &__input:valid ~ .c-file__label {
    //   border-color: @green;
    //   background: @green;
    //   color: @green;

    //   &:before {
    //     content: '\f00c';

    //     position: absolute;
    //       top: 0;
    //       right: 0;
    //       bottom: 0;
    //       left: 0;

    //     margin: auto;

    //     color: @white;
    //     font-family: 'FontAwesome';
    //     line-height: 45px;
    //   }
    // }
  }
}
