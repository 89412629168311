html {
  font-size: calc(100vw / 1440 * 10);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

// resets
body {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: $charcoal-8;

  &.no-scroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

[data-animation="text"] {
  .word {
    overflow: hidden;
    vertical-align: top;
    display: inline-flex;
    // padding-bottom: 0.2ch;
    // margin-top: -0.2ch;

    span {
      display: inline-block;
    }
  }
}
