.c-workplace {
  @extend %section-padding;
  padding-top: 14.5vh;
  padding-bottom: 14.5vh;

  @include media("<=tablet") {
    padding: 100px 0 0;
  }

  &__title {
    @extend %title-64;
    color: $charcoal-10;
    margin-bottom: 20px;
    text-align: center;

    @include media("<=tablet") {
      max-width: 342px;
      margin: 0 auto 20px;
    }
  }

  &__description {
    @extend %body-20;
    color: $charcoal-8;
    margin-bottom: 100px;
    text-align: center;

    @include media("<=tablet") {
      max-width: 275px;
      margin: 0 auto 60px;
    }
  }

  &__card-row {
    display: flex;
    @include flex-gap(60px);
    width: 100%;

    &--with-margin {
      margin-bottom: 100px;
    }

    @include media("<=SMdesktop") {
      flex-direction: column;
      margin-bottom: 40px;
      @include flex-gap(40px);
    }

    @include media("<=tablet") {
      margin-bottom: 0px;
      @include flex-gap(0px);
    }
  }

  &__card {
    padding: 62px 52px;
    border-radius: 20px;
    min-height: 718px;

    @include media("<=tablet") {
      padding: 60px 24px;
      min-height: unset;
      border-radius: 0;
    }

    &--small {
      flex: 1;
    }

    &--large {
      flex: 1.46;
    }

    &--collaborations {
      background-color: $geek-blue-1;
    }

    &--cultures {
      background-color: $magenta-1;
    }

    &--moments {
      background-color: $orange-1;
    }

    &--inspire {
      background-color: $green-0;
    }
  }

  &__card-title {
    @extend %title-38;
    color: $charcoal-10;
    margin-bottom: 20px;
  }

  &__card-description {
    @extend %body-18;
    color: $charcoal-9;

    &--collaborations {
      margin-bottom: 90px;
    }

    &--cultures {
      margin-bottom: 40px;
    }

    &--moments {
      margin-bottom: 64px;
    }

    &--inspire {
      margin-bottom: 88px;
    }

    @include media("<=tablet") {
      margin-bottom: 44px;
    }
  }

  &__recognition-card {
    box-shadow: 10px 10px 0px #D6E4FF;
    max-width: 600px;
  }

  .search-card {
    padding: 20px;
    background: $white;
    border-radius: 5px;
    margin-bottom: 40px;
    box-shadow: 10px 10px 0px #FFD6E7;
    max-width: 600px;

    &__input {
      height: 32px;
      display: flex;
      align-items: center;
      padding: 0 12px;
      background-color: $grey-1;
      border-radius: 2px;
      margin-bottom: 24px;
      color: $grey-5;
      @extend %body-14;
    }

    &__members {
      display: grid;
      grid-template-rows: 32px 32px;
      row-gap: 24px;
    }

    &__member {
      display: flex;
      align-items: center;
    }

    &__member-picture {
      width: 32px;
      height: 32px;
      border-radius: 50%;

      &--avatar {
        @extend %body-12;
        font-weight: 600;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $green-6;
      }
    }

    &__member-name {
      @extend %body-14;
      font-weight: 600;
      color: $charcoal-9;
      margin-left: 16px;
      margin-right: 10px;
    }

    &__member-role {
      @extend %body-12;
      color: $grey-5;
    }
  }

  &__hashtags {
    display: grid;
    grid-template-rows: repeat(5, 32px);
    align-items: center;
    background: #FFFFFF;
    box-shadow: 10px 10px 0px #F2D9E3;
    border-radius: 5px;
    padding-top: 4px;
    width: 164px;
    margin-left: auto;
  }

  &__hashtag {
    padding: 0 12px;
    @extend %body-14;
    color: $charcoal-9;
    height: 32px;
    display: flex;
    align-items: center;

    &--active {
      font-weight: 600;
      background-color: $green-0;
    }
  }

  &__inspire-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 52px;

    @include media("<=tablet") {
      grid-template-columns: 254px;
    }
  }

  &__inspire-card {
    padding: 20px;
    background: #FFFFFF;
    filter: drop-shadow(10px 10px 0px #A8EDC2);
    border-radius: 4px;

    @include media("<=tablet") {
      &:last-child {
        display: none;
      }
    }
  }

  &__inspire-card-image {
    padding: 5px;
    margin-bottom: 37px;
    background: $grey-1;
    border-radius: 4px;
    height: 104px;
    width: 100%;
  }

  &__inspire-card-title {
    @extend %body-14;
    color: $charcoal-9;
    margin-bottom: 12px;
    font-weight: 600;
  }

  &__inspire-card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__inspire-card-tag {
    height: 22px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $grey-4;
    border-radius: 100px;
    border: 1px solid $grey-4;
    @extend %body-12;
    color: $white;
  }

  &__inspire-card-onions {
    @extend %body-20;
    color: $green-6;
    font-weight: 600;
  }

  &__moments {
    display: grid;
    grid-template-rows: repeat(3, auto);
    row-gap: 32px;
    background: $white;
    box-shadow: 10px 10px 0px #F9E8D3;
    border-radius: 5px;
    padding: 40px;
  }

  &__moment {
    display: flex;
    justify-content: space-between;
  }

  &__moment-title {
    @extend %body-14;
    color: $charcoal-9;
    margin-bottom: 10px;
  }

  &__moment-placeholder {
    width: 178px;
    height: 25px;
    background-color: $grey-1;
    border-radius: 10px;
  }

  &__moment-switch {
    height: 16px;
    width: 28px;
    border-radius: 16px;
    background-color: $green-6;
    position: relative;

    &:after {
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 50%;
      position: absolute;
      top: 2px;
      right: 2px;
      background-color: $white;
    }
  }
}