.c-modal-wrapper {
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: z("modal");
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: none;

  &--active {
    opacity: 1;
    pointer-events: auto;
  }

  --footer-height: 115px;

  @include media("<=tablet") {
    height: calc(100% - 69px);

    --footer-height: 90px;
  }

  .c-modal--loading {
    position: absolute;
  }

  .c-modal {
    position: relative;
    height: min(90%, 794px);
    width: 100%;
    max-width: 684px;
    background-color: white;

    @include media("<=tablet") {
      height: 100%;
      box-shadow: 0px -1px 0px #f0f0f0;
    }

    &__form {
      padding-bottom: var(--footer-height);
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &__error {
      color: $error;
      @extend %body-14;
      margin-bottom: 24px;
    }

    &__content {
      flex-grow: 1;
      overflow: auto;
      padding: 92px 92px 40px;
      overscroll-behavior: contain;

      @include media("<=tablet") {
        padding: 24px 24px 40px;
      }

      &--partnerships {
        padding: 0 0 40px;

        .c-form {
          padding: 76px 92px 0;

          @include media("<=tablet") {
            padding: 0 24px 0;
          }
        }
      }
    }

    &__tag {
      background: $green-6;
      box-shadow: 10px 10px 0px $green-2;
      border-radius: 10px 10px 0 10px;
      padding: 12px 20px;
      margin-bottom: 32px;
      display: flex;
      align-items: center;
      width: fit-content;

      @include media("<=tablet") {
        padding: 8px 12px;
        border-radius: 8px 8px 0px 8px;
      }
    }

    &__tag--variant {
      background: $green-4;
      box-shadow: 10px 10px 0px $green-7;
      border-radius: 50px;
      padding: 4px 24px;
      margin-bottom: 32px;
      width: fit-content;
    }

    &__tag-text {
      @extend %title-20;
      color: white;
    }

    &__tag-text--variant {
      color: $charcoal-10;

      @include media("<=tablet") {
        font-size: 12px;
        line-height: 20px;
      }
    }

    &__tag-image {
      width: 28px;
      height: 28px;
      margin-left: 16px;

      @include media("<=tablet") {
        width: 22px;
        height: 22px;
        margin-left: 12px;
      }
    }

    &__title {
      @extend %title-24;
      color: $charcoal-10;
      margin-bottom: 20px;
    }

    &__description {
      @extend %body-14;
      color: $charcoal-8;
      margin-bottom: 48px;
    }

    &__partnerships {
      padding: 48px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include flex-gap(32px);
      background-color: $green-0;
    }

    &__partnerships-image {
      height: 40px;
    }

    &__partnerships-title {
      @extend %title-24;
      color: $charcoal-10;
      max-width: 588px;
      text-align: center;
    }

    &__partnerships-description {
      @extend %body-16;
      color: $charcoal-8;
      text-align: center;
    }

    &__footer {
      position: absolute;
      bottom: 0;
      left: 0;
      @include flex-gap(24px);
      align-items: center;
      height: var(--footer-height);
      width: 100%;
      padding: 0 92px;
      background: #ffffff;
      box-shadow: 0px -1px 25px rgba(0, 0, 0, 0.05);

      @include media("<=tablet") {
        padding: 24px;
      }
    }

    &__footer-button {
      display: flex;
      align-items: center;
      justify-content: center;
      @include flex-gap(4px);

      @include media("<=tablet") {
        padding: 10px 16px;
      }

      &--primary {
        @include media("<=tablet") {
          flex-grow: 1;
        }
      }

      .lds-ring {
        display: none;
      }

      &:disabled {
        pointer-events: none;
        opacity: 0.5;

        .lds-ring {
          position: relative;
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 16px;
            height: 16px;
            margin: 8px;
            border: 2px solid #fff;
            border-radius: 50%;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: #fff transparent transparent transparent;
          }
        }

        .lds-ring span:nth-child(1) {
          animation-delay: -0.45s;
        }
        .lds-ring span:nth-child(2) {
          animation-delay: -0.3s;
        }
        .lds-ring span:nth-child(3) {
          animation-delay: -0.15s;
        }
        @keyframes lds-ring {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }

  .c-form__success {
    position: absolute;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: $white;
    z-index: z("1");
    visibility: hidden;
    padding: 24px;

    h3 {
      @extend %title-40;
      color: $charcoal-10;
      margin-bottom: 20px;
    }

    h3,
    p {
      opacity: 0;
      transform: translateY(20px);
    }
  }

  &.loading {
    .c-modal__content {
      opacity: 0.5;
    }
  }

  &.success {
    .c-form__success {
      display: flex;
      visibility: visible;

      h3,
      p {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.3s ease, transform 0.3s ease;
      }

      p {
        transition-delay: 100ms;
      }
    }

    form {
      display: none;
    }
  }
}
