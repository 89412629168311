%title-96 {
  font-weight: 800;
  font-size: 96px;
  line-height: 110px;
  letter-spacing: calc(-0.005 * 64px);

  @include media("<=tablet") {
    font-size: 44px;
    line-height: 50px;
    letter-spacing: calc(-0.005 * 48px);
  }
}

%title-72 {
  font-weight: 800;
  font-size: 72px;
  line-height: 1;
  letter-spacing: calc(-0.005 * 64px);

  @include media("<=tablet") {
    font-size: 30px;
    letter-spacing: calc(-0.005 * 40px);
  }
}

%title-64 {
  font-weight: 700;
  font-size: 64px;
  line-height: 1;
  letter-spacing: calc(-0.005 * 64px);

  @include media("<=tablet") {
    font-size: 40px;
    letter-spacing: calc(-0.005 * 40px);
  }
}

%title-52 {
  font-weight: 800;
  font-size: 52px;
  line-height: 1;
  letter-spacing: calc(-0.005 * 52px);

  @include media("<=tablet") {
    font-size: 28px;
    line-height: 34px;
    letter-spacing: calc(-0.005 * 28px);
  }
}

%title-40 {
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  letter-spacing: calc(-0.002 * 40px);

  @include media("<=tablet") {
    font-size: 36px;
    letter-spacing: calc(-0.002 * 36px);
  }
}

%title-38 {
  font-weight: 600;
  font-size: 38px;
  line-height: 46px;
  letter-spacing: calc(-0.005 * 38px);

  @include media("<=tablet") {
    font-size: 28px;
    line-height: 30px;
    letter-spacing: calc(-0.005 * 28px);
  }
}

%title-36 {
  font-weight: 600;
  font-size: 36px;
  line-height: 38px;
  letter-spacing: calc(-0.02 * 36px);

  @include media("<=tablet") {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: calc(-0.005 * 24px);
  }
}

%title-30 {
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: calc(-0.02 * 30px);
}

%title-24 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: calc(-0.005 * 24px);

  @include media("<=tablet") {
    font-size: 18px;
    line-height: 24px;
  }
}

%title-20 {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: calc(-0.005 * 20px);

  @include media("<=tablet") {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: calc(-0.005 * 16px);
  }
}

%title-18 {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: calc(-0.005 * 24px);

  @include media("<=tablet") {
    font-size: 14px;
    line-height: 22px;
  }
}

%button-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: normal;
}

%body-20 {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: calc(-0.005 * 20px);

  @include media("<=tablet") {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: calc(-0.005 * 16px);
  }
}

%body-18 {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: calc(-0.005 * 18px);

  @include media("<=tablet") {
    font-size: 16px;
    letter-spacing: normal;
  }
}

%body-16 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: calc(-0.005 * 16px);

  @include media("<=tablet") {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: normal;
  }
}

%body-14 {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: normal;
}

%body-12 {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: normal;
}
