.c-plans {
  @extend %section-padding;
  padding-top: 132px;
  padding-bottom: 132px;

  @include media("<=tablet") {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  &__title {
    @extend %title-64;
    text-align: center;
    color: $grey-10;
    margin-bottom: 20px;
  }

  &__desc {
    @extend %body-20;
    text-align: center;
    margin-bottom: 80px;

    @include media("<=tablet") {
      margin-bottom: 60px;
    }
  }

  &__cards {
    display: flex;
    justify-content: center;
    @include flex-gap(24px, toRem(96));
    width: 100%;
    flex-wrap: wrap;

    @include media(">desktop") {
      @include flex-gap(24px, 96px);
    }

    .c-plan {
      max-width: 375px;
      width: 100%;
      border: 3px solid $grey-10;
      box-shadow: 10px 10px 0px #100f10;
      border-radius: 24px;
      padding: 40px 56px;

      &__button {
        @extend %body-14;
        font-weight: 600;
        width: 100%;
        padding: 10px 0;
        margin-top: auto;
        border-radius: 100px;
        transition: color 0.3s $ease-out-expo, background 0.3s $ease-out-expo;
      }

      &--pro {
        background: $green-6;
        color: $white;

        .c-plan {
          &__button {
            background: $grey-10;
            color: $green-4;
            border: 1px solid $green-6;

            @include cursor {
              &:hover {
                border: 1px solid $green-5;
                color: $green-5;
              }
            }
          }
        }
      }

      &--basic {
        .c-plan {
          &__button {
            background: transparent;
            border: 1px solid $green-6;
            color: $green-6;

            @include cursor {
              &:hover {
                border: 1px solid $green-5;
                color: $green-5;
              }
            }
          }

          &__price__amount {
            color: $charcoal-10;
          }
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      &__title {
        @extend %title-24;
        margin-bottom: 10px;
      }

      &__desc {
        @extend %body-14;
        margin-bottom: 36px;
      }

      &__price {
        display: flex;
        margin-bottom: 32px;
        align-items: flex-end;

        &__amount {
          @extend %title-30;
        }

        &__frequency {
          @extend %body-16;
          margin-left: 1px;
          margin-bottom: 4px;
        }
      }

      &__benefits {
        margin-bottom: 32px;
        .c-benefit {
          display: flex;
          align-items: center;

          &:not(:last-child) {
            margin-bottom: 16px;
          }

          &__icon {
            margin-right: 10px;
            width: 20px;
            height: 20px;

            svg {
              width: 100%;
              height: 100%;
            }
          }

          &__text {
            @extend %body-14;

            &--gold {
              color: $gold-2;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
