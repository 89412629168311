.recognition-card {
  padding: 40px;
  border: 1px solid $charcoal-4;
  border-radius: 5px;
  background-color: white;

  @include media("<=tablet") {
    padding: 24px;
  }

  &__transaction {
    display: grid;
    grid-template-columns: 51px 22px 34px;
    column-gap: 12px;
    align-items: center;
    margin-bottom: 36px;
  }

  &__transaction-onion {
    padding: 8px 12px;
    color: $green-6;
    @extend %body-14;
    background: $green-1;
    border-radius: 50px;
  }

  &__transaction-image {
    width: 100%;
  }

  &__title {
    @extend %body-16;
    color: $charcoal-10;
    margin-bottom: 12px;
  }

  &__description {
    @extend %body-16;
    color: $charcoal-8;
    font-weight: 400;
    margin-bottom: 20px;

    .semibold {
      font-weight: 600;
    }

    .onion {
      color: $green-6;
    }
  }

  &__sender {
    display: grid;
    grid-template-columns: 24px auto;
    column-gap: 3px;
    align-items: center;
  }

  &__sender-image {
    width: 100%;
  }

  &__sender-name {
    @extend %body-12;
    color: $grey-5
  }
}