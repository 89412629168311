@import "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap";
.c-values .c-value__imagecon img, .c-values .c-value__imagecon video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*, :before, :after {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  cursor: pointer;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: .694444vw;
}

body {
  letter-spacing: -.5px;
  color: #595959;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

body.no-scroll {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

[data-animation="text"] .word {
  vertical-align: top;
  display: inline-flex;
  overflow: hidden;
}

[data-animation="text"] .word span {
  display: inline-block;
}

.c-hero__title {
  letter-spacing: -.32px;
  font-size: 96px;
  font-weight: 800;
  line-height: 110px;
}

@media (max-width: 768px) {
  .c-hero__title {
    letter-spacing: -.24px;
    font-size: 44px;
    line-height: 50px;
  }
}

.c-info-section__description {
  letter-spacing: -.32px;
  font-size: 72px;
  font-weight: 800;
  line-height: 1;
}

@media (max-width: 768px) {
  .c-info-section__description {
    letter-spacing: -.2px;
    font-size: 30px;
  }
}

.c-legal__hero__title, .c-info-section__card-onion, .c-workplace__title, .c-plans__title {
  letter-spacing: -.32px;
  font-size: 64px;
  font-weight: 700;
  line-height: 1;
}

@media (max-width: 768px) {
  .c-legal__hero__title, .c-info-section__card-onion, .c-workplace__title, .c-plans__title {
    letter-spacing: -.2px;
    font-size: 40px;
  }
}

.c-info-section__title, .c-values .c-value__title {
  letter-spacing: -.26px;
  font-size: 52px;
  font-weight: 800;
  line-height: 1;
}

@media (max-width: 768px) {
  .c-info-section__title, .c-values .c-value__title {
    letter-spacing: -.14px;
    font-size: 28px;
    line-height: 34px;
  }
}

.c-modal-wrapper .c-form__success h3, .c-footer__logo, .c-nav__logo {
  letter-spacing: -.08px;
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
}

@media (max-width: 768px) {
  .c-modal-wrapper .c-form__success h3, .c-footer__logo, .c-nav__logo {
    letter-spacing: -.072px;
    font-size: 36px;
  }
}

.c-workplace__card-title {
  letter-spacing: -.19px;
  font-size: 38px;
  font-weight: 600;
  line-height: 46px;
}

@media (max-width: 768px) {
  .c-workplace__card-title {
    letter-spacing: -.14px;
    font-size: 28px;
    line-height: 30px;
  }
}

.c-legal h2 {
  letter-spacing: -.72px;
  font-size: 36px;
  font-weight: 600;
  line-height: 38px;
}

@media (max-width: 768px) {
  .c-legal h2 {
    letter-spacing: -.12px;
    font-size: 24px;
    line-height: 32px;
  }
}

.c-plans__cards .c-plan__price__amount {
  letter-spacing: -.6px;
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
}

.c-legal h3, .c-hero__subtext, .c-modal-wrapper .c-modal__partnerships-title, .c-modal-wrapper .c-modal__title, .c-values .c-value__pill span, .c-plans__cards .c-plan__title {
  letter-spacing: -.12px;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

@media (max-width: 768px) {
  .c-legal h3, .c-hero__subtext, .c-modal-wrapper .c-modal__partnerships-title, .c-modal-wrapper .c-modal__title, .c-values .c-value__pill span, .c-plans__cards .c-plan__title {
    font-size: 18px;
    line-height: 24px;
  }
}

.c-hero__beta, .c-modal-wrapper .c-modal__tag-text, .c-values .c-value__description, .c-footer__links .c-link__header {
  letter-spacing: -.1px;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

@media (max-width: 768px) {
  .c-hero__beta, .c-modal-wrapper .c-modal__tag-text, .c-values .c-value__description, .c-footer__links .c-link__header {
    letter-spacing: -.08px;
    font-size: 16px;
    line-height: 24px;
  }
}

.c-hero__cta, .c-info-section__card-hashtag {
  letter-spacing: -.12px;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
}

@media (max-width: 768px) {
  .c-hero__cta, .c-info-section__card-hashtag {
    font-size: 14px;
    line-height: 22px;
  }
}

.c-button {
  letter-spacing: normal;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
}

.c-legal__section .c-block--list li, .c-legal p, .c-workplace__inspire-card-onions, .c-workplace__description, .c-plans__desc {
  letter-spacing: -.1px;
  font-size: 20px;
  line-height: 28px;
}

@media (max-width: 768px) {
  .c-legal__section .c-block--list li, .c-legal p, .c-workplace__inspire-card-onions, .c-workplace__description, .c-plans__desc {
    letter-spacing: -.08px;
    font-size: 16px;
    line-height: 24px;
  }
}

.c-workplace__card-description {
  letter-spacing: -.09px;
  font-size: 18px;
  line-height: 24px;
}

@media (max-width: 768px) {
  .c-workplace__card-description {
    letter-spacing: normal;
    font-size: 16px;
  }
}

.c-modal-wrapper .c-modal__partnerships-description, .c-values .c-value__soon, .recognition-card__description, .recognition-card__title, .c-plans__cards .c-plan__price__frequency {
  letter-spacing: -.08px;
  font-size: 16px;
  line-height: 24px;
}

@media (max-width: 768px) {
  .c-modal-wrapper .c-modal__partnerships-description, .c-values .c-value__soon, .recognition-card__description, .recognition-card__title, .c-plans__cards .c-plan__price__frequency {
    letter-spacing: normal;
    font-size: 14px;
    line-height: 22px;
  }
}

.c-modal-wrapper .c-modal__description, .c-modal-wrapper .c-modal__error, .c-workplace__moment-title, .c-workplace__inspire-card-title, .c-workplace__hashtag, .c-workplace .search-card__member-name, .c-workplace .search-card__input, .recognition-card__transaction-onion, .c-plans__cards .c-plan__benefits .c-benefit__text, .c-plans__cards .c-plan__desc, .c-plans__cards .c-plan__button, .c-form .c-file__label, .c-form .c-label, .c-nav__button {
  letter-spacing: normal;
  font-size: 14px;
  line-height: 22px;
}

.c-workplace__inspire-card-tag, .c-workplace .search-card__member-role, .c-workplace .search-card__member-picture--avatar, .recognition-card__sender-name {
  letter-spacing: normal;
  font-size: 12px;
  line-height: 20px;
}

.c-footer__bottom__links a, .c-footer__links .c-link__item button, .c-footer__links .c-link__item a, .c-nav__link, .c-legal__section a {
  vertical-align: top;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.c-footer__bottom__links a:after, .c-footer__links .c-link__item button:after, .c-footer__links .c-link__item a:after, .c-nav__link:after, .c-legal__section a:after {
  content: "";
  height: 1px;
  width: 100%;
  background: currentColor;
  transition: transform .7s cubic-bezier(.19, 1, .22, 1);
  position: absolute;
  bottom: 0;
  left: 0;
}

.c-footer__bottom__links a:hover:after, .c-footer__links .c-link__item button:hover:after, .c-footer__links .c-link__item a:hover:after, .c-nav__link:hover:after, .c-legal__section a:after {
  transform-origin: 0;
  transform: scaleX(1);
}

.c-footer__bottom__links a:after, .c-footer__links .c-link__item button:after, .c-footer__links .c-link__item a:after, .c-nav__link:after, .c-legal__section a:hover:after {
  transform-origin: 100%;
  transform: scaleX(0);
}

.c-legal__section a, .c-footer__bottom__links a, .c-footer__links .c-link__item button, .c-footer__links .c-link__item a, .c-nav__link {
  display: inline-block;
}

.c-legal__section, .c-legal__hero, .c-hero, .c-info-section, .c-workplace, .c-plans, .c-footer, .c-nav {
  padding: 0 10rem;
}

@media (max-width: 768px) {
  .c-legal__section, .c-legal__hero, .c-hero, .c-info-section, .c-workplace, .c-plans, .c-footer, .c-nav {
    padding: 0 24px;
  }
}

.c-nav {
  height: 69px;
  width: 100%;
  z-index: 6;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  transition: transform .3s ease-out;
  display: flex;
  position: sticky;
  top: 0;
}

.c-nav.scrolling {
  transform: translateY(-100%);
}

.c-nav__logo {
  color: #0dba63;
}

.c-nav__links {
  text-transform: capitalize;
  align-items: center;
  gap: 32px;
  display: inline-flex;
}

@supports not (gap: 32px 32px) {
  .c-nav__links {
    --row-gap: 32px;
    --column-gap: 32px;
    margin: calc(-1 * var(--row-gap)) 0 0 calc(-1 * var(--column-gap));
    width: calc(100% + var(--column-gap));
  }

  .c-nav__links > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

@media (max-width: 768px) {
  .c-nav__links {
    display: none;
  }
}

.c-nav__link {
  font-size: 16px;
}

.c-nav__button {
  color: #019348;
  background: none;
  border: 1px solid #019348;
  border-radius: 100px;
  outline: none;
  padding: 12.5px 32px;
  transition: color .3s cubic-bezier(.19, 1, .22, 1), background .3s cubic-bezier(.19, 1, .22, 1);
}

@media (any-pointer: fine) {
  .c-nav__button:hover {
    color: #fff;
    background: #019348;
  }
}

.c-nav__hamburger {
  background: none;
  border: 0;
}

@media (min-width: 769px) {
  .c-nav__hamburger {
    display: none;
  }
}

.c-button {
  border-radius: 100px;
  padding: 6px 36px;
  transition: color .3s cubic-bezier(.19, 1, .22, 1), background .3s cubic-bezier(.19, 1, .22, 1), border .3s cubic-bezier(.19, 1, .22, 1);
}

.c-button--primary {
  color: #fff;
  background: #0dba63;
  border: 0;
}

@media (any-pointer: fine) {
  .c-button--primary:hover {
    background: #2ec775;
  }
}

.c-button--secondary {
  color: #80899b;
  background: none;
  border: 1px solid #80899b;
}

@media (any-pointer: fine) {
  .c-button--secondary:hover {
    color: #2ec775;
    border: 1px solid #2ec775;
  }
}

.c-form .c-label {
  margin-bottom: 8px;
  display: block;
}

.c-form .c-input {
  width: 100%;
  color: #262728;
  text-indent: 12px;
  background: none;
  border: 1px solid #c1c8d7;
  border-radius: 2px;
  outline: none;
  font-family: inherit;
  font-size: 14px;
  transition: border .3s cubic-bezier(.19, 1, .22, 1);
}

.c-form .c-input:-webkit-autofill, .c-form .c-input:-webkit-autofill:hover, .c-form .c-input:-webkit-autofill:focus, .c-form .c-input:-webkit-autofill:active {
  -webkit-text-fill-color: #262728;
  box-shadow: inset 0 0 0 30px #fff;
}

.c-form .c-input.placeholder {
  color: #c1c8d7;
  font-size: inherit;
  font-family: inherit;
}

.c-form .c-input::-webkit-input-placeholder {
  color: #c1c8d7;
  font-size: inherit;
  font-family: inherit;
}

.c-form .c-input::-moz-placeholder {
  color: #c1c8d7;
  font-size: inherit;
  font-family: inherit;
}

.c-form .c-input:-moz-placeholder {
  color: #c1c8d7;
  font-size: inherit;
  font-family: inherit;
}

.c-form .c-input:-ms-input-placeholder {
  color: #c1c8d7;
  font-size: inherit;
  font-family: inherit;
}

@media (any-pointer: fine) {
  .c-form .c-input:hover {
    border-color: #2ec775;
  }
}

.c-form .c-input:focus, .c-form .c-input:active {
  border-color: #0dba63;
  box-shadow: 0 0 4px #1890ff80;
}

.c-form__item:not(:last-child) {
  margin-bottom: 36px;
}

.c-form input {
  height: 40px;
}

.c-form textarea {
  height: 64px;
  resize: none;
  padding-top: 12px;
}

.c-form .c-file__input {
  width: .1px;
  height: .1px;
  opacity: 0;
  z-index: -1;
  position: absolute;
  overflow: hidden;
}

.c-form .c-file__label {
  display: block;
}

.c-form .c-file__label__inner {
  height: 156px;
  width: 100%;
  text-align: center;
  border: 1px dashed #c1c8d7;
  padding: 24px 16px;
  display: block;
}

.c-form .c-file__label__label {
  color: #0f1010;
  margin-bottom: 8px;
  display: inline-block;
}

.c-form .c-file__label__icon {
  text-align: center;
  justify-content: center;
  margin-bottom: 16px;
  display: flex;
}

.c-form .c-file__label__text {
  color: #80899b;
  display: block;
}

.c-footer {
  color: #fff;
  background: #101318;
}

.c-footer__top {
  padding-top: 32px;
  padding-bottom: 32px;
}

@media (min-width: 769px) {
  .c-footer__top {
    justify-content: space-between;
    padding-top: 60px;
    padding-bottom: 48px;
    display: flex;
  }
}

.c-footer__logo {
  color: #0dba63;
  margin-bottom: 32px;
}

@media (min-width: 769px) {
  .c-footer__logo {
    margin-bottom: 0;
    margin-right: 24px;
  }

  .c-footer__links {
    display: flex;
  }

  .c-footer__links__left {
    gap: 6rem;
    display: inline-flex;
  }

  @supports not (gap: 6rem 6rem) {
    .c-footer__links__left {
      --row-gap: 6rem;
      --column-gap: 6rem;
      margin: calc(-1 * var(--row-gap)) 0 0 calc(-1 * var(--column-gap));
      width: calc(100% + var(--column-gap));
    }

    .c-footer__links__left > * {
      margin: var(--row-gap) 0 0 var(--column-gap);
    }
  }
}

@media (max-width: 768px) {
  .c-footer__links__left .c-link__container {
    margin-bottom: 28px;
  }
}

@media (min-width: 769px) {
  .c-footer__links__right {
    max-width: 172px;
    margin-left: 12rem;
  }
}

.c-footer__links .c-link__header {
  color: #bfbfbf;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .c-footer__links .c-link__header {
    color: #595959;
    margin-bottom: 12px;
  }
}

.c-footer__links .c-link__item {
  display: flex;
}

@media (max-width: 768px) {
  .c-footer__links .c-link__item {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  }
}

.c-footer__links .c-link__item button, .c-footer__links .c-link__item a {
  color: inherit;
  font-size: inherit;
  background-color: #0000;
  border: none;
  padding: 0;
  text-decoration: none;
}

.c-footer__links .c-link__item:not(:last-child) {
  margin-bottom: 18px;
}

@media (max-width: 768px) {
  .c-footer__links .c-link__item:not(:last-child) {
    margin-bottom: 12px;
  }
}

.c-footer__address {
  margin: 40px 0;
}

@media (max-width: 768px) {
  .c-footer__address {
    border-bottom: 1px solid #595959;
    margin: 0 0 32px;
    padding-bottom: 32px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  }
}

.c-footer__socials {
  align-items: center;
  gap: 24px;
  display: inline-flex;
}

@supports not (gap: 24px 24px) {
  .c-footer__socials {
    --row-gap: 24px;
    --column-gap: 24px;
    margin: calc(-1 * var(--row-gap)) 0 0 calc(-1 * var(--column-gap));
    width: calc(100% + var(--column-gap));
  }

  .c-footer__socials > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

@media (min-width: 769px) {
  .c-footer__socials {
    margin-top: 40px;
  }
}

.c-footer__socials__link {
  transition: color .3s cubic-bezier(.19, 1, .22, 1);
  display: flex;
}

@media (any-pointer: fine) {
  .c-footer__socials__link:hover {
    color: #0dba63;
  }
}

.c-footer__bottom {
  width: 100%;
  border-top: 1px solid #595959;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;
  padding-top: 52px;
  padding-bottom: 60px;
  display: inline-flex;
}

@supports not (gap: 40px 40px) {
  .c-footer__bottom {
    --row-gap: 40px;
    --column-gap: 40px;
    margin: calc(-1 * var(--row-gap)) 0 0 calc(-1 * var(--column-gap));
    width: calc(100% + var(--column-gap));
  }

  .c-footer__bottom > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

@media (max-width: 768px) {
  .c-footer__bottom {
    gap: 32px;
    padding: 32px 0;
    font-size: 14px;
    line-height: 22px;
    display: inline-flex;
  }

  @supports not (gap: 32px 32px) {
    .c-footer__bottom {
      --row-gap: 32px;
      --column-gap: 32px;
      margin: calc(-1 * var(--row-gap)) 0 0 calc(-1 * var(--column-gap));
      width: calc(100% + var(--column-gap));
    }

    .c-footer__bottom > * {
      margin: var(--row-gap) 0 0 var(--column-gap);
    }
  }
}

.c-footer__bottom__links {
  flex-wrap: wrap;
  display: flex;
}

.c-footer__bottom__links > :not(:last-child) {
  border-right: 1px solid #d9d9d9;
  margin-right: 8px;
  padding-right: 8px;
}

.c-footer__copyright, .c-footer__rights {
  color: #d9d9d9;
}

.c-plans {
  padding-top: 132px;
  padding-bottom: 132px;
}

@media (max-width: 768px) {
  .c-plans {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.c-plans__title {
  text-align: center;
  color: #101318;
  margin-bottom: 20px;
}

.c-plans__desc {
  text-align: center;
  margin-bottom: 80px;
}

@media (max-width: 768px) {
  .c-plans__desc {
    margin-bottom: 60px;
  }
}

.c-plans__cards {
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px 9.6rem;
  display: inline-flex;
}

@supports not (gap: 24px 9.6rem) {
  .c-plans__cards {
    --row-gap: 24px;
    --column-gap: 9.6rem;
    margin: calc(-1 * var(--row-gap)) 0 0 calc(-1 * var(--column-gap));
    width: calc(100% + var(--column-gap));
  }

  .c-plans__cards > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

@media (min-width: 1441px) {
  .c-plans__cards {
    gap: 24px 96px;
    display: inline-flex;
  }

  @supports not (gap: 24px 96px) {
    .c-plans__cards {
      --row-gap: 24px;
      --column-gap: 96px;
      margin: calc(-1 * var(--row-gap)) 0 0 calc(-1 * var(--column-gap));
      width: calc(100% + var(--column-gap));
    }

    .c-plans__cards > * {
      margin: var(--row-gap) 0 0 var(--column-gap);
    }
  }
}

.c-plans__cards .c-plan {
  max-width: 375px;
  width: 100%;
  border: 3px solid #101318;
  border-radius: 24px;
  padding: 40px 56px;
  box-shadow: 10px 10px #100f10;
}

.c-plans__cards .c-plan__button {
  width: 100%;
  border-radius: 100px;
  margin-top: auto;
  padding: 10px 0;
  font-weight: 600;
  transition: color .3s cubic-bezier(.19, 1, .22, 1), background .3s cubic-bezier(.19, 1, .22, 1);
}

.c-plans__cards .c-plan--pro {
  color: #fff;
  background: #0dba63;
}

.c-plans__cards .c-plan--pro .c-plan__button {
  color: #53d48a;
  background: #101318;
  border: 1px solid #0dba63;
}

@media (any-pointer: fine) {
  .c-plans__cards .c-plan--pro .c-plan__button:hover {
    color: #2ec775;
    border: 1px solid #2ec775;
  }
}

.c-plans__cards .c-plan--basic .c-plan__button {
  color: #0dba63;
  background: none;
  border: 1px solid #0dba63;
}

@media (any-pointer: fine) {
  .c-plans__cards .c-plan--basic .c-plan__button:hover {
    color: #2ec775;
    border: 1px solid #2ec775;
  }
}

.c-plans__cards .c-plan--basic .c-plan__price__amount {
  color: #0f1010;
}

.c-plans__cards .c-plan__content {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.c-plans__cards .c-plan__title {
  margin-bottom: 10px;
}

.c-plans__cards .c-plan__desc {
  margin-bottom: 36px;
}

.c-plans__cards .c-plan__price {
  align-items: flex-end;
  margin-bottom: 32px;
  display: flex;
}

.c-plans__cards .c-plan__price__frequency {
  margin-bottom: 4px;
  margin-left: 1px;
}

.c-plans__cards .c-plan__benefits {
  margin-bottom: 32px;
}

.c-plans__cards .c-plan__benefits .c-benefit {
  align-items: center;
  display: flex;
}

.c-plans__cards .c-plan__benefits .c-benefit:not(:last-child) {
  margin-bottom: 16px;
}

.c-plans__cards .c-plan__benefits .c-benefit__icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.c-plans__cards .c-plan__benefits .c-benefit__icon svg {
  width: 100%;
  height: 100%;
}

.c-plans__cards .c-plan__benefits .c-benefit__text--gold {
  color: #fff1b8;
  font-weight: 600;
}

.recognition-card {
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  padding: 40px;
}

@media (max-width: 768px) {
  .recognition-card {
    padding: 24px;
  }
}

.recognition-card__transaction {
  grid-template-columns: 51px 22px 34px;
  align-items: center;
  column-gap: 12px;
  margin-bottom: 36px;
  display: grid;
}

.recognition-card__transaction-onion {
  color: #0dba63;
  background: #e1fae9;
  border-radius: 50px;
  padding: 8px 12px;
}

.recognition-card__transaction-image {
  width: 100%;
}

.recognition-card__title {
  color: #0f1010;
  margin-bottom: 12px;
}

.recognition-card__description {
  color: #595959;
  margin-bottom: 20px;
  font-weight: 400;
}

.recognition-card__description .semibold {
  font-weight: 600;
}

.recognition-card__description .onion {
  color: #0dba63;
}

.recognition-card__sender {
  grid-template-columns: 24px auto;
  align-items: center;
  column-gap: 3px;
  display: grid;
}

.recognition-card__sender-image {
  width: 100%;
}

.recognition-card__sender-name {
  color: #919aad;
}

.c-workplace {
  padding-top: 14.5vh;
  padding-bottom: 14.5vh;
}

@media (max-width: 768px) {
  .c-workplace {
    padding: 100px 0 0;
  }
}

.c-workplace__title {
  color: #0f1010;
  text-align: center;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .c-workplace__title {
    max-width: 342px;
    margin: 0 auto 20px;
  }
}

.c-workplace__description {
  color: #595959;
  text-align: center;
  margin-bottom: 100px;
}

@media (max-width: 768px) {
  .c-workplace__description {
    max-width: 275px;
    margin: 0 auto 60px;
  }
}

.c-workplace__card-row {
  width: 100%;
  gap: 60px;
  display: inline-flex;
}

@supports not (gap: 60px 60px) {
  .c-workplace__card-row {
    --row-gap: 60px;
    --column-gap: 60px;
    margin: calc(-1 * var(--row-gap)) 0 0 calc(-1 * var(--column-gap));
    width: calc(100% + var(--column-gap));
  }

  .c-workplace__card-row > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

.c-workplace__card-row--with-margin {
  margin-bottom: 100px;
}

@media (max-width: 960px) {
  .c-workplace__card-row {
    flex-direction: column;
    gap: 40px;
    margin-bottom: 40px;
    display: inline-flex;
  }

  @supports not (gap: 40px 40px) {
    .c-workplace__card-row {
      --row-gap: 40px;
      --column-gap: 40px;
      margin: calc(-1 * var(--row-gap)) 0 0 calc(-1 * var(--column-gap));
      width: calc(100% + var(--column-gap));
    }

    .c-workplace__card-row > * {
      margin: var(--row-gap) 0 0 var(--column-gap);
    }
  }
}

@media (max-width: 768px) {
  .c-workplace__card-row {
    gap: 0;
    margin-bottom: 0;
    display: inline-flex;
  }

  @supports not (gap: 0px 0px) {
    .c-workplace__card-row {
      --row-gap: 0px;
      --column-gap: 0px;
      margin: calc(-1 * var(--row-gap)) 0 0 calc(-1 * var(--column-gap));
      width: calc(100% + var(--column-gap));
    }

    .c-workplace__card-row > * {
      margin: var(--row-gap) 0 0 var(--column-gap);
    }
  }
}

.c-workplace__card {
  min-height: 718px;
  border-radius: 20px;
  padding: 62px 52px;
}

@media (max-width: 768px) {
  .c-workplace__card {
    min-height: unset;
    border-radius: 0;
    padding: 60px 24px;
  }
}

.c-workplace__card--small {
  flex: 1;
}

.c-workplace__card--large {
  flex: 1.46;
}

.c-workplace__card--collaborations {
  background-color: #f0f5ff;
}

.c-workplace__card--cultures {
  background-color: #fff0f6;
}

.c-workplace__card--moments {
  background-color: #fffbf6;
}

.c-workplace__card--inspire {
  background-color: #f2fff7;
}

.c-workplace__card-title {
  color: #0f1010;
  margin-bottom: 20px;
}

.c-workplace__card-description {
  color: #262728;
}

.c-workplace__card-description--collaborations {
  margin-bottom: 90px;
}

.c-workplace__card-description--cultures {
  margin-bottom: 40px;
}

.c-workplace__card-description--moments {
  margin-bottom: 64px;
}

.c-workplace__card-description--inspire {
  margin-bottom: 88px;
}

@media (max-width: 768px) {
  .c-workplace__card-description {
    margin-bottom: 44px;
  }
}

.c-workplace__recognition-card {
  max-width: 600px;
  box-shadow: 10px 10px #d6e4ff;
}

.c-workplace .search-card {
  max-width: 600px;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 40px;
  padding: 20px;
  box-shadow: 10px 10px #ffd6e7;
}

.c-workplace .search-card__input {
  height: 32px;
  color: #919aad;
  background-color: #f6f7f9;
  border-radius: 2px;
  align-items: center;
  margin-bottom: 24px;
  padding: 0 12px;
  display: flex;
}

.c-workplace .search-card__members {
  grid-template-rows: 32px 32px;
  row-gap: 24px;
  display: grid;
}

.c-workplace .search-card__member {
  align-items: center;
  display: flex;
}

.c-workplace .search-card__member-picture {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.c-workplace .search-card__member-picture--avatar {
  color: #fff;
  background-color: #0dba63;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  display: flex;
}

.c-workplace .search-card__member-name {
  color: #262728;
  margin-left: 16px;
  margin-right: 10px;
  font-weight: 600;
}

.c-workplace .search-card__member-role {
  color: #919aad;
}

.c-workplace__hashtags {
  width: 164px;
  background: #fff;
  border-radius: 5px;
  grid-template-rows: repeat(5, 32px);
  align-items: center;
  margin-left: auto;
  padding-top: 4px;
  display: grid;
  box-shadow: 10px 10px #f2d9e3;
}

.c-workplace__hashtag {
  color: #262728;
  height: 32px;
  align-items: center;
  padding: 0 12px;
  display: flex;
}

.c-workplace__hashtag--active {
  background-color: #f2fff7;
  font-weight: 600;
}

.c-workplace__inspire-cards {
  grid-column-gap: 52px;
  grid-template-columns: 1fr 1fr;
  display: grid;
}

@media (max-width: 768px) {
  .c-workplace__inspire-cards {
    grid-template-columns: 254px;
  }
}

.c-workplace__inspire-card {
  filter: drop-shadow(10px 10px #a8edc2);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
}

@media (max-width: 768px) {
  .c-workplace__inspire-card:last-child {
    display: none;
  }
}

.c-workplace__inspire-card-image {
  height: 104px;
  width: 100%;
  background: #f6f7f9;
  border-radius: 4px;
  margin-bottom: 37px;
  padding: 5px;
}

.c-workplace__inspire-card-title {
  color: #262728;
  margin-bottom: 12px;
  font-weight: 600;
}

.c-workplace__inspire-card-footer {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.c-workplace__inspire-card-tag {
  height: 22px;
  color: #fff;
  background-color: #b5bac6;
  border: 1px solid #b5bac6;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  display: flex;
}

.c-workplace__inspire-card-onions {
  color: #0dba63;
  font-weight: 600;
}

.c-workplace__moments {
  background: #fff;
  border-radius: 5px;
  grid-template-rows: repeat(3, auto);
  row-gap: 32px;
  padding: 40px;
  display: grid;
  box-shadow: 10px 10px #f9e8d3;
}

.c-workplace__moment {
  justify-content: space-between;
  display: flex;
}

.c-workplace__moment-title {
  color: #262728;
  margin-bottom: 10px;
}

.c-workplace__moment-placeholder {
  width: 178px;
  height: 25px;
  background-color: #f6f7f9;
  border-radius: 10px;
}

.c-workplace__moment-switch {
  height: 16px;
  width: 28px;
  background-color: #0dba63;
  border-radius: 16px;
  position: relative;
}

.c-workplace__moment-switch:after {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  right: 2px;
}

@media (min-width: 769px) {
  .c-values {
    height: 100vh;
    flex-wrap: nowrap;
    display: flex;
  }
}

.c-values .c-value {
  flex: none;
  padding: 60px 24px;
  position: relative;
  overflow: hidden;
}

@media (min-width: 769px) {
  .c-values .c-value {
    width: 1325px;
    height: 100%;
    flex-shrink: 0;
  }

  .c-values .c-value:not(:last-child) {
    margin-right: 16px;
  }
}

.c-values .c-value__icon {
  justify-content: flex-end;
  margin-bottom: 40px;
  display: flex;
}

.c-values .c-value__soon {
  background: #262728;
  border-radius: 50px;
  margin-bottom: 32px;
  padding: 4px 50px;
  display: inline-block;
}

@media (max-width: 768px) {
  .c-values .c-value__soon {
    margin-bottom: 24px;
  }
}

.c-values .c-value__pill {
  z-index: 2;
  width: fit-content;
  background: #fff;
  border: 1px solid #0f1010;
  border-radius: 100px;
  padding: 4px 36px;
  font-style: italic;
  display: inline-block;
  position: absolute;
  box-shadow: 4px 4px #53d48a;
}

@media (min-width: 769px) {
  .c-values .c-value__pill {
    padding: 4px 24px;
  }
}

.c-values .c-value__pill span {
  font-style: normal;
}

.c-values .c-value__profile {
  z-index: 1;
  margin: 0 auto 96px;
  position: relative;
}

.c-values .c-value__imagecon {
  max-width: 500px;
  aspect-ratio: 1.08571;
  margin: 0 auto;
  font-size: 0;
  position: relative;
  overflow: hidden;
}

@supports not (aspect-ratio: calc(342 / 315)) {
  .c-values .c-value__imagecon:after {
    content: "";
    width: 100%;
    padding-top: 92.1053%;
    display: inline-block;
  }
}

.c-values .c-value__imagecon img, .c-values .c-value__imagecon video {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .c-values .c-value__imagecon {
    background: #262728;
    border-radius: 100px;
    padding: 40px 24px 0;
  }
}

.c-values .c-value__image {
  width: 100%;
  height: 100%;
  position: relative;
}

.c-values .c-value__content {
  color: #fff;
}

@media (max-width: 768px) {
  .c-values .c-value__content {
    margin: 0 auto;
  }
}

.c-values .c-value__title {
  margin-bottom: 24px;
}

@media (max-width: 768px) {
  .c-values .c-value__description {
    font-weight: 400;
  }
}

.c-values .c-value--first {
  background: #0f1010;
}

@media (min-width: 769px) {
  .c-values .c-value--first {
    flex-direction: column;
    align-items: flex-end;
    padding-right: 92px;
    display: flex;
  }
}

.c-values .c-value--first .c-value__icon {
  max-width: 156px;
  width: 100%;
  margin-left: auto;
}

@media (max-width: 768px) {
  .c-values .c-value--first .c-value__icon {
    max-width: 81px;
  }
}

.c-values .c-value--first .c-value__pill {
  bottom: -12px;
  right: 14px;
  transform: rotate(-12deg);
}

@media (min-width: 769px) {
  .c-values .c-value--first .c-value__pill {
    bottom: 84px;
    right: -142px;
    transform: rotate(16.38deg);
  }
}

.c-values .c-value--first .c-value__content {
  max-width: 580px;
}

.c-values .c-value--first .c-value__profile {
  max-width: 506px;
}

@media (min-width: 769px) {
  .c-values .c-value--first .c-value__profile {
    width: 100%;
    margin: 0;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.c-values .c-value--first .c-value__line {
  display: none;
}

@media (min-width: 769px) {
  .c-values .c-value--first .c-value__line {
    width: 100%;
    max-width: 522px;
    display: block;
    position: absolute;
    bottom: 0;
    left: -14px;
  }
}

.c-values .c-value--second {
  background: #0dba63;
}

@media (min-width: 769px) {
  .c-values .c-value--second {
    flex-direction: column;
    align-items: flex-end;
    padding-right: 92px;
    display: flex;
  }
}

.c-values .c-value--second .c-value__icon {
  max-width: 96px;
  width: 100%;
  margin-left: auto;
}

@media (max-width: 768px) {
  .c-values .c-value--second .c-value__icon {
    max-width: 50px;
  }
}

.c-values .c-value--second .c-value__pill {
  bottom: -12px;
  left: 24px;
  transform: rotate(12deg);
  box-shadow: 4px 4px #fff1b8;
}

@media (min-width: 769px) {
  .c-values .c-value--second .c-value__pill {
    bottom: 72px;
    left: -142px;
    transform: rotate(-4deg);
  }
}

.c-values .c-value--second .c-value__profile {
  max-width: 506px;
}

@media (min-width: 769px) {
  .c-values .c-value--second .c-value__profile {
    width: 100%;
    margin: 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.c-values .c-value--second .c-value__line {
  display: none;
}

@media (min-width: 769px) {
  .c-values .c-value--second .c-value__line {
    width: 100%;
    max-width: 581px;
    margin: 0;
    display: block;
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-60%);
  }
}

@media (max-width: 768px) {
  .c-values .c-value--second .c-value__content {
    max-width: 580px;
    margin: 0 auto;
  }
}

@media (min-width: 769px) {
  .c-values .c-value--second .c-value__title {
    max-width: 395px;
    position: absolute;
    top: 151px;
    left: 80px;
  }

  .c-values .c-value--second .c-value__description {
    max-width: 364px;
    position: absolute;
    bottom: 122px;
    right: 46px;
  }
}

@media (max-width: 768px) {
  .c-values .c-value--second .c-value__imagecon {
    background: #019348;
  }
}

.c-values .c-value--third {
  background: #0f1010;
}

@media (min-width: 769px) {
  .c-values .c-value--third {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 80px;
    display: flex;
  }
}

.c-values .c-value--third .c-value__icon {
  max-width: 62px;
  width: 100%;
  margin-left: auto;
}

@media (min-width: 769px) {
  .c-values .c-value--third .c-value__icon {
    max-width: 117px;
    margin-left: unset;
    margin-bottom: 105px;
  }
}

.c-values .c-value--third .c-value__pill {
  bottom: -12px;
  right: 14px;
  transform: rotate(-12deg);
}

@media (min-width: 769px) {
  .c-values .c-value--third .c-value__pill {
    bottom: 60px;
    left: -180px;
    transform: rotate(7deg);
  }
}

@media (max-width: 768px) {
  .c-values .c-value--third .c-value__content {
    max-width: 580px;
    margin: 0 auto;
  }
}

@media (min-width: 769px) {
  .c-values .c-value--third .c-value__title {
    max-width: 615px;
  }

  .c-values .c-value--third .c-value__description {
    max-width: 587px;
  }
}

.c-values .c-value--third .c-value__profile {
  max-width: 462px;
}

@media (min-width: 769px) {
  .c-values .c-value--third .c-value__profile {
    width: 100%;
    margin: 0;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.c-values .c-value--third .c-value__line {
  display: none;
}

@media (min-width: 769px) {
  .c-values .c-value--third .c-value__line {
    width: 100%;
    max-width: 522px;
    display: block;
    position: absolute;
    bottom: -7px;
    right: -18px;
  }
}

.c-modal-wrapper {
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;
  z-index: 7;
  opacity: 0;
  pointer-events: none;
  --footer-height: 115px;
  background-color: #0000004d;
  justify-content: center;
  align-items: center;
  transition: none;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
}

.c-modal-wrapper--active {
  opacity: 1;
  pointer-events: auto;
}

@media (max-width: 768px) {
  .c-modal-wrapper {
    height: calc(100% - 69px);
    --footer-height: 90px;
  }
}

.c-modal-wrapper .c-modal--loading {
  position: absolute;
}

.c-modal-wrapper .c-modal {
  height: min(90%, 794px);
  width: 100%;
  max-width: 684px;
  background-color: #fff;
  position: relative;
}

@media (max-width: 768px) {
  .c-modal-wrapper .c-modal {
    height: 100%;
    box-shadow: 0 -1px #f0f0f0;
  }
}

.c-modal-wrapper .c-modal__form {
  padding-bottom: var(--footer-height);
  height: 100%;
  flex-direction: column;
  display: flex;
}

.c-modal-wrapper .c-modal__error {
  color: red;
  margin-bottom: 24px;
}

.c-modal-wrapper .c-modal__content {
  overscroll-behavior: contain;
  flex-grow: 1;
  padding: 92px 92px 40px;
  overflow: auto;
}

@media (max-width: 768px) {
  .c-modal-wrapper .c-modal__content {
    padding: 24px 24px 40px;
  }
}

.c-modal-wrapper .c-modal__content--partnerships {
  padding: 0 0 40px;
}

.c-modal-wrapper .c-modal__content--partnerships .c-form {
  padding: 76px 92px 0;
}

@media (max-width: 768px) {
  .c-modal-wrapper .c-modal__content--partnerships .c-form {
    padding: 0 24px;
  }
}

.c-modal-wrapper .c-modal__tag {
  width: fit-content;
  background: #0dba63;
  border-radius: 10px 10px 0;
  align-items: center;
  margin-bottom: 32px;
  padding: 12px 20px;
  display: flex;
  box-shadow: 10px 10px #a8edc2;
}

@media (max-width: 768px) {
  .c-modal-wrapper .c-modal__tag {
    border-radius: 8px 8px 0;
    padding: 8px 12px;
  }
}

.c-modal-wrapper .c-modal__tag--variant {
  width: fit-content;
  background: #53d48a;
  border-radius: 50px;
  margin-bottom: 32px;
  padding: 4px 24px;
  box-shadow: 10px 10px #019348;
}

.c-modal-wrapper .c-modal__tag-text {
  color: #fff;
}

.c-modal-wrapper .c-modal__tag-text--variant {
  color: #0f1010;
}

@media (max-width: 768px) {
  .c-modal-wrapper .c-modal__tag-text--variant {
    font-size: 12px;
    line-height: 20px;
  }
}

.c-modal-wrapper .c-modal__tag-image {
  width: 28px;
  height: 28px;
  margin-left: 16px;
}

@media (max-width: 768px) {
  .c-modal-wrapper .c-modal__tag-image {
    width: 22px;
    height: 22px;
    margin-left: 12px;
  }
}

.c-modal-wrapper .c-modal__title {
  color: #0f1010;
  margin-bottom: 20px;
}

.c-modal-wrapper .c-modal__description {
  color: #595959;
  margin-bottom: 48px;
}

.c-modal-wrapper .c-modal__partnerships {
  background-color: #f2fff7;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 48px;
  display: inline-flex;
}

@supports not (gap: 32px 32px) {
  .c-modal-wrapper .c-modal__partnerships {
    --row-gap: 32px;
    --column-gap: 32px;
    margin: calc(-1 * var(--row-gap)) 0 0 calc(-1 * var(--column-gap));
    width: calc(100% + var(--column-gap));
  }

  .c-modal-wrapper .c-modal__partnerships > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

.c-modal-wrapper .c-modal__partnerships-image {
  height: 40px;
}

.c-modal-wrapper .c-modal__partnerships-title {
  color: #0f1010;
  max-width: 588px;
  text-align: center;
}

.c-modal-wrapper .c-modal__partnerships-description {
  color: #595959;
  text-align: center;
}

.c-modal-wrapper .c-modal__footer {
  height: var(--footer-height);
  width: 100%;
  background: #fff;
  align-items: center;
  gap: 24px;
  padding: 0 92px;
  display: inline-flex;
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: 0 -1px 25px #0000000d;
}

@supports not (gap: 24px 24px) {
  .c-modal-wrapper .c-modal__footer {
    --row-gap: 24px;
    --column-gap: 24px;
    margin: calc(-1 * var(--row-gap)) 0 0 calc(-1 * var(--column-gap));
    width: calc(100% + var(--column-gap));
  }

  .c-modal-wrapper .c-modal__footer > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

@media (max-width: 768px) {
  .c-modal-wrapper .c-modal__footer {
    padding: 24px;
  }
}

.c-modal-wrapper .c-modal__footer-button {
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: inline-flex;
}

@supports not (gap: 4px 4px) {
  .c-modal-wrapper .c-modal__footer-button {
    --row-gap: 4px;
    --column-gap: 4px;
    margin: calc(-1 * var(--row-gap)) 0 0 calc(-1 * var(--column-gap));
    width: calc(100% + var(--column-gap));
  }

  .c-modal-wrapper .c-modal__footer-button > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

@media (max-width: 768px) {
  .c-modal-wrapper .c-modal__footer-button {
    padding: 10px 16px;
  }

  .c-modal-wrapper .c-modal__footer-button--primary {
    flex-grow: 1;
  }
}

.c-modal-wrapper .c-modal__footer-button .lds-ring {
  display: none;
}

.c-modal-wrapper .c-modal__footer-button:disabled {
  pointer-events: none;
  opacity: .5;
}

.c-modal-wrapper .c-modal__footer-button:disabled .lds-ring {
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.c-modal-wrapper .c-modal__footer-button:disabled .lds-ring span {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border: 2px solid #0000;
  border-top-color: #fff;
  border-radius: 50%;
  margin: 8px;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite lds-ring;
  display: block;
  position: absolute;
}

.c-modal-wrapper .c-modal__footer-button:disabled .lds-ring span:nth-child(1) {
  animation-delay: -.45s;
}

.c-modal-wrapper .c-modal__footer-button:disabled .lds-ring span:nth-child(2) {
  animation-delay: -.3s;
}

.c-modal-wrapper .c-modal__footer-button:disabled .lds-ring span:nth-child(3) {
  animation-delay: -.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.c-modal-wrapper .c-form__success {
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 1;
  visibility: hidden;
  background: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  position: absolute;
}

.c-modal-wrapper .c-form__success h3 {
  color: #0f1010;
  margin-bottom: 20px;
}

.c-modal-wrapper .c-form__success h3, .c-modal-wrapper .c-form__success p {
  opacity: 0;
  transform: translateY(20px);
}

.c-modal-wrapper.loading .c-modal__content {
  opacity: .5;
}

.c-modal-wrapper.success .c-form__success {
  visibility: visible;
  display: flex;
}

.c-modal-wrapper.success .c-form__success h3, .c-modal-wrapper.success .c-form__success p {
  opacity: 1;
  transition: opacity .3s, transform .3s;
  transform: translateY(0);
}

.c-modal-wrapper.success .c-form__success p {
  transition-delay: .1s;
}

.c-modal-wrapper.success form {
  display: none;
}

.c-menu {
  width: 100%;
  height: 100vh;
  z-index: 8;
  opacity: 0;
  pointer-events: none;
  background: #fff;
  flex-direction: column;
  transition: opacity .3s cubic-bezier(.19, 1, .22, 1);
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

@media (min-width: 769px) {
  .c-menu {
    display: none;
  }
}

.c-menu.open {
  opacity: 1;
  pointer-events: auto;
}

.c-menu__top {
  height: 69px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding: 0 24px;
  display: flex;
}

.c-menu__bottom {
  margin-top: auto;
  margin-bottom: 24px;
  padding: 0 24px;
}

.c-menu__bottom .c-button {
  width: 100%;
}

.c-menu__close {
  background: none;
  border: 0;
}

.c-menu__item {
  letter-spacing: -.08px;
  color: inherit;
  background: none;
  border: 0;
  margin: 0;
  padding: 8px 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  display: block;
}

.c-menu__item:not(:last-child) {
  margin-bottom: 8px;
}

.c-home {
  position: relative;
}

.c-info-section {
  min-height: 100vh;
  width: 100%;
  background-color: #101318;
  justify-content: space-between;
  padding-top: 10.2vh;
  padding-bottom: 9.4vh;
  display: flex;
  position: relative;
}

@media (max-width: 1000px) {
  .c-info-section {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .c-info-section {
    justify-content: unset;
    flex-direction: column;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }

  .c-info-section--collaboration {
    padding-top: 68px;
    padding-bottom: 88px;
  }

  .c-info-section--integrations {
    min-height: auto;
    padding-bottom: 40px;
  }
}

.c-info-section__squiggly {
  z-index: 1;
  position: absolute;
  bottom: 25.9%;
  left: 0;
}

@media (max-width: 768px) {
  .c-info-section__squiggly {
    display: none;
  }
}

.c-info-section__details {
  z-index: 2;
  margin-top: 210px;
  position: relative;
}

@media (max-width: 1000px) {
  .c-info-section__details {
    margin: 0 auto 80px;
  }
}

@media (max-width: 768px) {
  .c-info-section__details {
    margin-top: 0;
  }
}

.c-info-section__title {
  color: #101318;
  text-shadow: -1px -1px #fff, 1px -1px #fff, -1px 1px #fff, 1px 1px #fff;
  margin-bottom: 20px;
  font-style: italic;
  position: relative;
}

@media (max-width: 1000px) {
  .c-info-section__title {
    width: fit-content;
    margin: 0 auto 12px;
  }
}

@media (max-width: 768px) {
  .c-info-section__title {
    margin: 0 0 12px;
  }
}

.c-info-section__title span {
  z-index: 2;
  position: relative;
}

.c-info-section__title:after {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-5px)translateY(5px);
}

.c-info-section__title--collaboration:after {
  content: "Easily Foster";
  text-shadow: -1px -1px #ffc53d, 1px -1px #ffc53d, -1px 1px #ffc53d, 1px 1px #ffc53d;
}

.c-info-section__title--integrations:after {
  content: "Silky Smooth";
  text-shadow: -1px -1px #0dba63, 1px -1px #0dba63, -1px 1px #0dba63, 1px 1px #0dba63;
}

.c-info-section__description {
  width: 100%;
  max-width: 491px;
  color: #fff;
}

@media (max-width: 1000px) {
  .c-info-section__description {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .c-info-section__description {
    text-align: left;
  }
}

.c-info-section__card-wrapper {
  width: 100%;
  max-width: 342px;
  margin: 0 auto;
  position: relative;
}

.c-info-section__card-star {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-59px)translateY(-57px);
}

@media (max-width: 768px) {
  .c-info-section__card-star {
    width: 77px;
    height: 77px;
    transform: translate(-42px, -41px);
  }
}

.c-info-section__card {
  height: 100%;
  width: 40vw;
  max-width: 567px;
  border-radius: 10px;
  padding: 0 14.5%;
  position: relative;
}

.c-info-section__card--collaboration {
  background-color: #53d48a;
  padding-top: 100px;
}

@media (max-width: 1000px) {
  .c-info-section__card--collaboration {
    height: 783px;
  }
}

@media (max-width: 768px) {
  .c-info-section__card--collaboration {
    height: 583px;
  }
}

.c-info-section__card--integrations {
  background-color: #fff1b8;
  padding-top: 20%;
}

@media (max-width: 1000px) {
  .c-info-section__card--integrations {
    padding-bottom: 70px;
  }
}

@media (max-width: 768px) {
  .c-info-section__card {
    width: 100%;
    max-width: 342px;
    padding: 50px 38px;
  }
}

.c-info-section__recognition-card {
  box-shadow: 10px 10px #fff1b8;
}

.c-info-section__card-hashtag {
  width: fit-content;
  transform-origin: 100% 100%;
  border: 1px solid #000;
  border-radius: 100px;
  padding: 4px 24px;
  position: absolute;
  box-shadow: 4px 4px #019348;
}

.c-info-section__card-hashtag--world-class {
  transform-origin: 0 100%;
  background: #ffccc7;
  bottom: 13%;
  right: 13.2%;
  transform: rotate(-17.7deg);
}

@media (max-width: 768px) {
  .c-info-section__card-hashtag--world-class {
    bottom: 15.78%;
    right: 7%;
  }
}

.c-info-section__card-hashtag--welcome {
  background-color: #fff3e3;
  bottom: 19.6%;
  left: 29.5%;
  transform: rotate(21.42deg);
}

@media (max-width: 768px) {
  .c-info-section__card-hashtag--welcome {
    bottom: 25.55%;
    left: 24.85%;
  }
}

.c-info-section__card-hashtag--rockstar {
  background-color: #fff;
  bottom: 16.2%;
  left: 7.9%;
  transform: rotate(13.41deg);
}

@media (max-width: 768px) {
  .c-info-section__card-hashtag--rockstar {
    bottom: 17%;
    left: 5.5%;
  }
}

.c-info-section__card-hashtag--stronger {
  background-color: #fff;
  bottom: 4.26%;
  left: 26%;
  transform: rotate(4.62deg);
}

@media (max-width: 768px) {
  .c-info-section__card-hashtag--stronger {
    bottom: 7.9%;
  }
}

.c-info-section__card-onion {
  color: #0dba63;
  width: fit-content;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 100px;
  margin: 0 auto 18%;
  padding: 40px;
  box-shadow: 10px 10px #0dba63;
}

@media (max-width: 768px) {
  .c-info-section__card-onion {
    margin-bottom: 88px;
    padding: 24px 40px;
  }
}

.c-info-section__card-integration-row {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;
  display: flex;
}

.c-info-section__card-integration-icon {
  width: fit-content;
  background: #fff;
  border: 1px solid #000;
  border-radius: 100px;
  padding: 20px 40px;
  box-shadow: 10px 10px #0dba63;
}

@media (max-width: 768px) {
  .c-info-section__card-integration-icon {
    padding: 20px 32px;
  }
}

.c-info-section__card-integration-icon svg {
  width: 40px;
  height: 40px;
}

@media (max-width: 768px) {
  .c-info-section__card-integration-icon svg {
    width: 24px;
    height: 24px;
  }
}

.c-info-section__card-integration-icon--center {
  margin: 0 auto;
}

.c-hero {
  min-height: 100vh;
  background: #f2fff7;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

@media (max-width: 768px) {
  .c-hero {
    justify-content: unset;
    padding-top: 56px;
  }
}

.c-hero__beta {
  height: 36px;
  color: #0f1010;
  width: fit-content;
  background-color: #7be0a4;
  border-radius: 50px;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 28px;
  display: flex;
}

.c-hero__beta span {
  backface-visibility: hidden;
  transform-origin: 100% 0;
  margin-right: 3px;
  animation: .6s cubic-bezier(.36, .07, .19, .97) 2.5s both bellshake;
  display: inline-block;
}

.c-hero__title {
  text-align: center;
  color: #101318;
  width: 100%;
  max-width: 1121px;
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .c-hero__title {
    max-width: 342px;
  }
}

.c-hero__subtext {
  text-align: center;
  color: #101318;
  opacity: 0;
  margin-bottom: 72px;
  font-weight: 400;
}

@media (max-width: 768px) {
  .c-hero__subtext {
    width: 100%;
    max-width: 342px;
    margin-bottom: 40px;
  }
}

.c-hero__cta {
  width: 354px;
  height: 58px;
  color: #fff;
  opacity: 0;
  background: #101318;
  border: 1px solid #101318;
  border-radius: 100px;
  outline: none;
  justify-content: center;
  align-items: center;
  transition: color .3s cubic-bezier(.19, 1, .22, 1), background .3s cubic-bezier(.19, 1, .22, 1), border .3s cubic-bezier(.19, 1, .22, 1);
  display: flex;
}

.c-hero__cta:hover {
  color: #101318;
  background-color: #f2fff7;
}

@media (max-width: 768px) {
  .c-hero__cta {
    width: 100%;
    max-width: 342px;
    height: 42px;
  }
}

.c-hero__recognition {
  align-items: flex-start;
  column-gap: 4px;
  display: grid;
  position: absolute;
}

.c-hero__recognition .c-hero__recognition__cursor {
  opacity: 0;
}

@media (max-width: 768px) {
  .c-hero__recognition {
    display: none;
  }
}

.c-hero__recognition--left {
  width: 32.7rem;
  height: 23.3rem;
  grid-template-columns: 1fr 1.43fr;
  bottom: 7.36%;
  left: 5.06%;
}

.c-hero__recognition--left [data-cursor-left-2] {
  position: relative;
  top: -5px;
  left: 15px;
}

.c-hero__recognition--right {
  width: 33.2rem;
  height: 23.1rem;
  grid-template-columns: 1fr 2.6fr 1fr;
  top: 7.8%;
  right: 6.88%;
}

.c-hero__recognition__column {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.c-hero__recognition__cursor {
  width: 100%;
}

.c-hero__recognition__cursor--small {
  width: 10.8rem;
}

.c-hero__recognition__card {
  width: 100%;
  background: #fff;
  border: .2rem solid #101318;
  border-radius: 1rem;
  padding: 1.6rem 1.2rem;
  position: relative;
  box-shadow: .4rem .4rem #0dba63;
}

.c-hero__recognition__card--left {
  margin-top: 27.89%;
}

.c-hero__recognition__card--right {
  margin-top: 30%;
}

.c-hero__recognition__card-header {
  align-items: center;
  margin-bottom: .8rem;
  display: flex;
}

.c-hero__recognition__card-onions-icon {
  width: 4rem;
  margin-right: 1.6rem;
}

.c-hero__recognition__card-icons-row {
  grid-template-columns: repeat(3, 3.2rem);
  column-gap: .8rem;
  display: grid;
}

.c-hero__recognition__card-icon {
  width: 100%;
}

.c-hero__recognition__title {
  color: #101318;
  margin-bottom: .8rem;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.67;
}

.c-hero__recognition__title span span {
  opacity: 0;
}

.c-hero__recognition__divider {
  height: .4rem;
  width: 100%;
  background-color: #f6f7f9;
  border: none;
  border-radius: .2rem;
  margin: 0;
  display: block;
}

.c-hero__star {
  width: 9rem;
  height: 10.3rem;
  max-width: 90px;
  max-height: 103px;
  position: absolute;
  bottom: 28.9%;
  right: 18.3%;
}

@media (max-width: 1440px) {
  .c-hero__star {
    bottom: 18%;
  }
}

@media (max-width: 768px) {
  .c-hero__star {
    width: 34px;
    height: 34px;
    bottom: unset;
    right: unset;
    margin: 0 30px 48px auto;
    position: relative;
  }
}

.c-hero__recognition-mobile {
  width: 100%;
  max-width: 342px;
  margin-top: 40px;
}

@media (min-width: 769px) {
  .c-hero__recognition-mobile {
    display: none;
  }
}

@keyframes bellshake {
  0% {
    transform: rotate(0)scale(1);
  }

  15% {
    transform: rotate(5deg)scale(1);
  }

  30% {
    transform: rotate(-5deg)scale(1.06);
  }

  45% {
    transform: rotate(4deg)scale(1.08);
  }

  60% {
    transform: rotate(-4deg)scale(1.1);
  }

  75% {
    transform: rotate(2deg)scale(1.08);
  }

  85% {
    transform: rotate(-2deg)scale(1.06);
  }

  92% {
    transform: rotate(1deg)scale(1);
  }

  100% {
    transform: rotate(0)scale(1);
  }
}

.c-legal {
  margin-bottom: 180px;
}

@media (max-width: 768px) {
  .c-legal {
    margin-bottom: 100px;
  }
}

.c-legal__hero {
  color: #fff;
  text-align: center;
  background: #0dba63;
  margin-bottom: 112px;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (max-width: 768px) {
  .c-legal__hero {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.c-legal__hero__title {
  text-transform: uppercase;
  margin-bottom: 10px;
}

.c-legal b {
  font-weight: 600;
}

.c-legal i {
  font-style: italic;
}

.c-legal h2 {
  margin-bottom: 40px;
}

.c-legal h3 {
  margin-bottom: 12px;
}

.c-legal__section p {
  margin-bottom: revert;
}

.c-legal__section a {
  color: #2f54eb;
}

.c-legal__section:not(:last-child) {
  margin-bottom: 72px;
}

.c-legal__section .c-block--text:not(:last-child) {
  margin-bottom: 40px;
}

.c-legal__section .c-block--list {
  padding: revert;
  list-style: disc;
}

.c-legal__section .c-block--list li:not(:last-child) {
  margin-bottom: 12px;
}

/*# sourceMappingURL=index.86a75edc.css.map */
